import { useContext, useState, useEffect } from "react";
import { Image, Button, Spinner } from "@fluentui/react-components";
import "./Welcome.css";
import { useData } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "../Context";
import * as microsoftTeams from "@microsoft/teams-js";

console.log(microsoftTeams)

export function Welcome(props) {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { data } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });

  const [userData, setUserData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  console.log(data)
  console.log(userData)
  console.log('updated app')

  useEffect(() => {
    if (!userData && data?.tenantId) {
      setIsFetching(true);
      
      fetch(`${process.env.REACT_APP_API_URL}/teams-app/tenant?tenant_id=${data?.tenantId}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setUserData(true);
            setIsFetching(false);
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            setIsFetching(false);
            setUserData(false);
        });
    }
  }, [data, userData]); // Step 5: Dependency Array

  if (isFetching) return <Spinner style={{ margin: 100 }} />;

  console.log(data)

  return (
    <div className="welcome page welcome-page">
      <div className="narrow page-padding">

        <Image className="versational-banner" src="versational_info.svg" />

        {userData ? <>
          <div className="success-wrapper">
            <Image className="versational-logo" src="versational.png" />
            <div className="container"><span className="arrow-left"></span><span className="arrow-right"></span></div>
            <Image className="teams-logo" src="teams.png" />
          </div>
          <h1 className="center">Congratulations!</h1>
          <p className="center">The application is connected to your Versational® account.</p>

          <div className="center">
          <br />
          <Button appearance="primary" onClick={async () => {
              try {
                await fetch(`${process.env.REACT_APP_API_URL}/teams-app/tenant?tenant_id=${data?.tenantId}`, {
                  method: 'DELETE',
                })

                setUserData(null)
              } catch (error) {
                console.log(error)
              }
              
            }}>Disconnect</Button>
            </div>
        </> :
        <div>
          <h1 className="center">Connect to Versational®</h1>
          <br />
          <div className="center">
            <Button onClick={() => {
              console.log('authentication!')
              microsoftTeams.authentication.authenticate({
                  url: window.location.origin + "/#/auth/simple-start",
                  width: 600,
                  height: 535,
                  successCallback: (result) => {
                      console.log('authentication success!')
                      console.log(result)
                      setUserData(result)
                  },
                  failureCallback: (reason) => {
                      console.log('authentication failure!')
                  }
              });
            }}>Connect</Button>
          </div>
        </div>
        }

      </div>
    </div>
  );
}
