import React from "react";
import { Image } from "@fluentui/react-components";

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class TermsOfUse extends React.Component {
  render() {
    return (
      <div class="help-page narrow">
        <h1>How to use Versational® Microsoft Teams App</h1>
        <h2>1. Connect Teams App to Versational</h2>
        <p>Click the “Connect” button to connect Teams App to Versational.</p>
        <Image src="instruction-1.png" alt="Connect Button" />

        <p>Input your email address or use an authentication provider to create a Versational account.</p>
        <Image src="instruction-2.png" alt="Email Address Input" />

        <p>Link your account by clicking “Link accounts”.</p>
        <Image src="instruction-3.png" alt="Link Accounts" />

        <p>Versational is now connected to the Teams App.</p>
        <Image src="instruction-4.png" alt="Versational Connected" />

        <h2>2. Start and Record a Meeting</h2>
        <p>Start a meeting on Microsoft Teams and record it. The recording should be at least 2 minutes long.</p>
        <Image src="instruction-5.png" alt="Start and Record Meeting" />

        <p>Stop the recording. The meeting notes from Versational should be posted on the meeting chat within a few minutes. For long recordings, it can take longer.</p>
        <Image src="instruction-6.png" alt="Stop Recording and Meeting Notes" />
      </div>
    );
  }
}

export default TermsOfUse;
