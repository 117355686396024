import React from "react";
import { useEffect } from "react";
import { app } from "@microsoft/teams-js";
import { useLocation } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { Spinner } from "@fluentui/react-components";


/**
 * This component is used to display the required
 * privacy statement which can be found in a link in the
 * about tab.
 */
function AuthStart() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');

  useEffect(() => {
    const fetchData = async () => {
      await app.initialize();

      if (status === 'error') {
        microsoftTeams.authentication.notifyFailure('Error. Please try again.');
      } else {
        microsoftTeams.authentication.notifySuccess('Success. Nane name:');
      }
    };

    fetchData();
  }, [status]);

  return (
    <div className="auth-page">
      <Spinner />
    </div>
  );
}

export default AuthStart;
