import React from "react";
import { useContext, useEffect } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import { app } from "@microsoft/teams-js";
import { Spinner } from "@fluentui/react-components";


/**
 * This component is used to display the required
 * privacy statement which can be found in a link in the
 * about tab.
 */
function AuthStart() {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { data } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });

  useEffect(() => {
    let isMounted = true; // Flag to track mounted state
  
    // Define the async function outside of useEffect
    const fetchData = async () => {
      if (!data || !isMounted) return;
  
      await app.initialize();
      await app.getContext();
    
      // parse data object to url parameters
      const params = new URLSearchParams();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          params.append(key, data[key]);
        }
      }
      const query = params.toString();

      let callbackPart = window.location.origin + "/#/auth/simple-end";
      let encodedCallbackPart = encodeURIComponent(callbackPart);

      let authorizeEndpoint = `${process.env.REACT_APP_URL}/auth?mode=teamsapp&callback=` + encodedCallbackPart;

      // Add any additional query parameters if needed
      authorizeEndpoint += "&" + query;

      console.log(authorizeEndpoint)
        
      console.log(authorizeEndpoint)
      if (isMounted) window.location.assign(authorizeEndpoint);
    };
  
    // Call the async function
    fetchData();
  
    // Cleanup function
    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, [data]); // Dependency array

  return (
    <div className="auth-page">
      <Spinner />
    </div>
  );
}

export default AuthStart;
